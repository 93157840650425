import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import "./index.css";
import Header from "../sections/A_Header";
import Footer from "../sections/K_Footer";
import { Container } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/Layout";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`;

const Content = styled(Container)`
  margin-top: 200px;
  width: 800px;
`;

const TermsPage = () => {
  const { t } = useTranslation();
  return (
    <Layout pageTitle={`WeTechFood | ${t("tos.title")}`}>
      <Wrapper id="home">
        <Header />
        <Content>
          <h1>{t("tos.title")}</h1>
        </Content>
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default TermsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
